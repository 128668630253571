import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import imgC1 from "../../assets/image/svg/class.svg";
import imgC2 from "../../assets/image/svg/video.svg";
import imgC3 from "../../assets/image/svg/file.svg";

import imgDot from "../../assets/image/l5/png/l5-dot-shape2.png";

const Services = () => {
  return (
    <>
      {/* <!--Service section  -->*/}
      <div className="service-section bg-default-4 pt-15 pb-13 py-lg-25">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="7" xl="6">
              <div className="section-title text-center mb-11 mb-lg-19 px-lg-3">
                <h4 className="pre-title gr-text-12 text-red text-uppercase mb-7">
                  Shortcuts
                </h4>
                <h2 className="title gr-text-4">
                  Informasi yang mungkin kamu butuhkan
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center position-relative gr-z-index-1">
            <Col
              md="6"
              lg="4"
              className="mb-9 mb-lg-0"
            >
              <div className="service-card rounded-10 gr-hover-shadow-4 d-flex flex-column text-center pt-15 px-9 pb-11 dark-mode-texts bg-green h-100">
                <div className="card-img mb-11">
                  <img src={imgC1} width="128" alt="..." />
                </div>
                <h3 className="card-title gr-text-6 mb-6">Belajar Untuk Pemula</h3>
                <p className="gr-text-9 mb-11">
                  Ikut berdiskusi dengan anggota lainnya di dalam group telegram yang
                  kami buat untuk kamu yang baru mau belajar.
                </p>
                <a
                  href="https://t.me/N00BSquad" target="_blank"
                  className="gr-text-9 btn-link with-icon text-white mt-auto"
                >
                  Selengkapnya{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </a>
              </div>
            </Col>
            <Col
              md="6"
              lg="4"
              className="mb-9 mb-lg-0"
            >
              <div className="service-card rounded-10 gr-hover-shadow-4 d-flex flex-column text-center pt-15 px-9 pb-11 dark-mode-texts bg-blue h-100">
                <div className="card-img mb-11">
                  <img src={imgC2} width="128" alt="..." />
                </div>
                <h3 className="card-title gr-text-6 mb-6">Live TV</h3>
                <p className="gr-text-9 mb-11">
                  <i>Online conference</i> yang menghadirkan sejumlah pembicara untuk
                  berbagi dengan anggota Ethical Hacker Indonesia.
                </p>
                <Link to="/tv"
                  className="gr-text-9 btn-link with-icon text-white mt-auto"
                >
                  Selengkapnya{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
            <Col
              md="6"
              lg="4"
              className="mb-9 mb-lg-0"
            >
              <div className="service-card rounded-10 gr-hover-shadow-4 d-flex flex-column text-center pt-15 px-9 pb-11 dark-mode-texts bg-red h-100">
                <div className="card-img mb-11">
                  <img src={imgC3} width="128" alt="..." />
                </div>
                <h3 className="card-title gr-text-6 mb-6">Periksa Data</h3>
                <p className="gr-text-9 mb-11">
                  Apakah berbagai kejadian kebocoran data di internet turut mengorbankan data milik kamu?
                  Periksa sekarang dan silakan lihat hasilnya.
                </p>
                <a
                  href="https://periksadata.com" target="_blank"
                  className="gr-text-9 btn-link with-icon text-white mt-auto"
                >
                  Selengkapnya{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </a>
              </div>
              <div
                className="gr-abs-br-custom gr-z-index-n1"
              >
                <img src={imgDot} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Services;
