import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import icon1 from "../../assets/image/svg/bugs.svg";
import icon2 from "../../assets/image/svg/covid.svg";
import icon3 from "../../assets/image/svg/file.svg";
import icon4 from "../../assets/image/svg/l5-feature-icon4.svg";

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <div className="feature-section pt-14 pt-lg-25 pb-7 pb-lg-11 bg-default-2">
      <Container>
        <Row className="justify-content-center">
          <Col xl="6" lg="7" md="8">
            <div className="section-title text-center mb-13 mb-lg-23">
              <h4 className="pre-title gr-text-12 text-red text-uppercase mb-7">
                Projects
              </h4>
              <h2 className="title gr-text-4">
                Beberapa projek yang kami hadirkan untuk membantu masyarakat luas.
              </h2>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="10">
            <Row className="align-items-center justify-content-center">
              <Col
                lg="6"
                md="10"
                className="mb-11 mb-lg-19"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 mr-9 gr-bg-blue-opacity-1">
                    <img src={icon1} width="36" height="36" alt="" />
                  </div>
                  <div className="widget-text">
                  <a href="https://osint.sh/" target="_blank">
                    <h3 className="title gr-text-7 mb-6">
                      OSINT.SH
                    </h3>
                  </a>  
                    <p className="gr-text-9 mb-0 pr-11">
                      Sekumpulan tools yang kamu butuhkan untuk <i>information gathering</i>.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                md="10"
                className="mb-11 mb-lg-19"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 mr-9 gr-bg-red-opacity-1">
                    <img src={icon2} width="36" height="36" alt="" />
                  </div>
                  <div className="widget-text">
                  <a href="https://kawalcorona.com/" target="_blank">
                    <h3 className="title gr-text-7 mb-6">Kawal Corona</h3>
                  </a>  
                    <p className="gr-text-9 mb-0 pr-11">
                      Data coronavirus global dan Indonesia berikut dengan API.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                md="10"
                className="mb-11 mb-lg-19"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 mr-9 gr-bg-green-opacity-1">
                    <img src={icon3} width="36" height="36" alt="" />
                  </div>
                  <div className="widget-text">
                  <a href="https://periksadata.com" target="_blank">
                    <h3 className="title gr-text-7 mb-6">
                      Periksa Data
                    </h3>
                  </a>  
                    <p className="gr-text-9 mb-0 pr-11">
                      Untuk memeriksa kebocoran data yang kamu alami.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                md="10"
                className="mb-11 mb-lg-19"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 mr-9 gr-bg-blackish-blue-opacity-1">
                    <img src={icon4} alt="" />
                  </div>
                  <div className="widget-text">
                    <h3 className="title gr-text-7 mb-6">
                      Coming soon
                    </h3>
                    <p className="gr-text-9 mb-0 pr-11">
                      Ditunggu ya untuk projek kami selanjutnya...
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Feature;
