import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <div className="cta-section-2 bg-default-2 ">
      <Container>
        <div className="cta-wrapper pt-14 pb-14 py-lg-19 border-bottom">
          <Row className="align-items-center justify-content-center">
            <Col lg="6" md="10">
              <div className="cta-text section-title">
                <h2 className="title gr-text-5 mb-7">
                  Ingin ikut terlibat sebagai kontributor?
                </h2>
                <p className="gr-text-8 mb-8 mb-lg-0">
                  Kami terbuka untuk memuat tulisan kamu di blog Ethical Hacker Indonesia. Kirimkan <i>link</i> tulisan kamu sekarang.
                </p>
              </div>
            </Col>
            <Col lg="4" md="10" className="offset-lg-2">
              <div className="cta-btn text-lg-right">
                <Link to="/kirim" className="btn btn-blue px-9 py-5 ml-lg-9">Kirim tulisan</Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  </>
);

export default CTA;