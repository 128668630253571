import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing/Hero";
import Shortcuts from "../sections/landing/Shortcuts";
import Fact from "../sections/landing/Fact";
import Projects from "../sections/landing/Projects";
import Blog from "../sections/landing/Blog";
import CTA from "../sections/landing/CTA";

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
      > 
        <Hero />
        <Shortcuts />
        <Fact />
        <Projects />
        <Blog />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
