import React from "react";

import git from "../../assets/image/l5/jpg/git.jpg";
import indihome from "../../assets/image/l5/jpg/indihome.jpg";
import envato from "../../assets/image/l5/jpg/envato.jpg";

const Blog = () => (
  <>
    {/* <!-- CaseStudies section --> */}
    <div class="news-section pt-12 pb-8 py-lg-23 bg-default-4">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-7 col-md-9">
        <div class="section-title text-center mb-11 mb-lg-18">
          <h2 class="title gr-text-4 mb-6">Blog</h2>
          <p class="gr-text-8 px-lg-8 mb-0">Tulisan dari kontributor Ethical Hacker Indonesia.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://teguh.co/derita-pelanggan-indihome-sudah-bayar-datanya-malah-dicuri/" target="_blank" class="card-img">
            <img src={indihome} alt="" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://teguh.co/derita-pelanggan-indihome-sudah-bayar-datanya-malah-dicuri/" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Privacy</p>
            </a>
            <a href="https://teguh.co/derita-pelanggan-indihome-sudah-bayar-datanya-malah-dicuri/" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Derita Pelanggan Indihome, Sudah Bayar Datanya Malah Dicuri</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://teguh.co/wednesday-challenge-publicly-accessible-git-directory/" target="_blank" class="card-img">
            <img src={git} alt="" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://teguh.co/wednesday-challenge-publicly-accessible-git-directory/" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Bug Bounty</p>
            </a>
            <a href="https://teguh.co/wednesday-challenge-publicly-accessible-git-directory/" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Wednesday Challenge : Publicly Accessible GIT Directory</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://teguh.co/ssrf-vulnerability-dan-aws-s3-bucket-misconfiguration-di-placeit-envato/" target="_blank" class="card-img">
            <img src={envato} alt="" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://teguh.co/ssrf-vulnerability-dan-aws-s3-bucket-misconfiguration-di-placeit-envato/" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Bug Bounty</p>
            </a>
            <a href="https://teguh.co/ssrf-vulnerability-dan-aws-s3-bucket-misconfiguration-di-placeit-envato/" target="_blank">
              <h3 class="title text-blackish-blue gr-text-7 mb-0">SSRF Vulnerability dan AWS S3 Bucket Misconfiguration di Placeit (Envato)</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </>
);

export default Blog;
