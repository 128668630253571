import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import imgL1Hero from "../../assets/image/svg/cubes.svg";
import imgDot from "../../assets/image/l5/png/l5-dot-shape.png";
import imgB1 from "../../assets/image/l4/png/dana.png";
import imgB2 from "../../assets/image/l4/png/zit.png";
import imgB3 from "../../assets/image/l4/png/tordillas.png";
import imgB4 from "../../assets/image/l4/png/4n6labs.png";
import imgB5 from "../../assets/image/l4/png/idx.png";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="position-relative bg-default-2 bg-pattern pattern-2 pt-27 pt-lg-32 pb-15 pb-lg-27">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col
              xs="9"
              md="7"
              lg="5"
              className="offset-xl-1 align-self-sm-end order-lg-2"
            >
              <div
                className="hero-img position-relative"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
              >
                <img className="w-100" src={imgL1Hero} alt="" />
                <div
                  className="gr-abs-tl gr-z-index-n1"
                  data-aos="zoom-in"
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                >
                  <img src={imgDot} alt="" />
                </div>
              </div>
            </Col>
            <Col
              xs="11"
              md="9"
              lg="7"
              xl="6"
              className="order-lg-1"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
            >
              <div className="hero-content mt-11 mt-lg-0 text-center text-lg-left">
                <h4 className="pre-title gr-text-12 text-red text-uppercase mb-7">
                  Mari ikut terlibat
                </h4>
                <h1 className="title gr-text-2 mb-8">
                  Berdiskusi langsung dengan kami
                </h1>
                <p className="gr-text-8 mb-11 pr-md-12">
                  Untuk membahas segala sesuatu yang berhubungan dengan <i>cyber security</i>.
                </p>
                <div className="hero-btn">
                  <Button href="https://www.facebook.com/groups/h4cker.id/" target="_blank" className="gr-hover-y">
                    Gabung Sekarang
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <div className="brand-section pt-18">
          <Row className="justify-content-center align-items-center">
            <Col md="8">
              <p className="gr-text-9 text-center mb-7">
                PERNAH BERKOLABORASI DENGAN
              </p>
            </Col>
            <Col xs="12">
              <div className="brand-logos d-flex justify-content-center justify-content-xl-between align-items-center mx-n9 flex-wrap">
                <div
                  className="single-brand mx-9 py-6 gr-opacity-8 gr-hover-opacity-full"
                >
                  <img src={imgB1} alt="" width="100" />
                </div>
                <div
                  className="single-brand mx-9 py-6 gr-opacity-8 gr-hover-opacity-full"
                  data-aos="zoom-in-right"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <img src={imgB2} alt="" width="100" />
                </div>
                <div
                  className="single-brand mx-9 py-6 gr-opacity-8 gr-hover-opacity-full"
                  data-aos="zoom-in-right"
                  data-aos-duration="500"
                  data-aos-delay="400"
                >
                  <img src={imgB3} alt="" width="100" />
                </div>
                <div
                  className="single-brand mx-9 py-6 gr-opacity-8 gr-hover-opacity-full"
                  data-aos="zoom-in-right"
                  data-aos-duration="500"
                  data-aos-delay="600"
                >
                  <img src={imgB4} alt="" width="100" />
                </div>
                <div
                  className="single-brand mx-9 py-6 gr-opacity-8 gr-hover-opacity-full"
                  data-aos="zoom-in-right"
                  data-aos-duration="800"
                  data-aos-delay="800"
                >
                  <img src={imgB5} alt="" width="100" />
                </div>
              </div>
            </Col>
          </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Hero;
